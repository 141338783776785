import dynamic from 'next/dynamic';
import React from 'react';

import { CardType } from 'components/Core/WalletForms/validations/src/types';

export const Address = dynamic(() => import('components/Shared/Icons/Address'), {
  loading: () => null,
});
export const CreditKarmaNoText = dynamic(
  () => import('components/Shared/Icons/CreditKarmaNoText'),
  {
    loading: () => null,
  }
);

export const Bank = dynamic(() => import('components/Shared/Icons/Bank'), { loading: () => null });
export const CreditCard = dynamic(() => import('components/Shared/Icons/CreditCard'), {
  loading: () => null,
});
export const DebitCard = dynamic(() => import('components/Shared/Icons/DebitCard'), {
  loading: () => null,
});
export const Info = dynamic(() => import('components/Shared/Icons/Info'), { loading: () => null });
export const Lock = dynamic(() => import('components/Shared/Icons/Lock'), { loading: () => null });
export const Chevron = dynamic(() => import('components/Shared/Icons/Chevron'), {
  loading: () => null,
});
export const Caret = dynamic(() => import('components/Shared/Icons/Caret'), {
  loading: () => null,
});
export const Check = dynamic(() => import('components/Shared/Icons/Check'), {
  loading: () => null,
});
export const Contact = dynamic(() => import('components/Shared/Icons/Contact'), {
  loading: () => null,
});
export const Download = dynamic(() => import('components/Shared/Icons/Download'), {
  loading: () => null,
});
export const DownloadOld = dynamic(() => import('components/Shared/Icons/DownloadOld'), {
  loading: () => null,
});
export const Print = dynamic(() => import('components/Shared/Icons/Print'), {
  loading: () => null,
});
export const PrintOld = dynamic(() => import('components/Shared/Icons/PrintOld'), {
  loading: () => null,
});
export const ErrorInfo = dynamic(() => import('components/Shared/Icons/ErrorInfo'), {
  loading: () => null,
});
export const ErrorAlert = dynamic(() => import('components/Shared/Icons/ErrorAlertIcon'), {
  loading: () => null,
});
export const Delete = dynamic(() => import('components/Shared/Icons/Delete'), {
  loading: () => null,
});
export const QuestionMark = dynamic(() => import('components/Shared/Icons/QuestionMark'), {
  loading: () => null,
});
export const DotsMenu = dynamic(() => import('components/Shared/Icons/DotsMenu'), {
  loading: () => null,
});
export const ApplePay = dynamic(() => import('components/Shared/Icons/ApplePay'), {
  loading: () => null,
});
export const PayPal = dynamic(() => import('components/Shared/Icons/PayPal'), {
  loading: () => null,
});
export const PaypalPPAAM = dynamic(() => import('components/Shared/Icons/PaypalPPAAM'), {
  loading: () => null,
});
export const PaypalPPAAMPayLater = dynamic(
  () => import('components/Shared/Icons/PaypalPPAAMPayLater'),
  {
    loading: () => null,
  }
);
export const Venmo = dynamic(() => import('components/Shared/Icons/Venmo'), {
  loading: () => null,
});
export const SuccessIcon = dynamic(() => import('components/Shared/Icons/SuccessIcon'), {
  loading: () => null,
});
export const GreenSuccessIcon = dynamic(() => import('components/Shared/Icons/GreenSuccessIcon'), {
  loading: () => null,
});
export const AccountNumberInfo = dynamic(
  () => import('components/Core/Payment/PaymentForm/Bank/Ach/AccountNumberInfo/AccountNumberInfo'),
  {
    loading: () => null,
  }
);
export const RoutingNumberInfo = dynamic(
  () => import('components/Shared/Icons/RoutingNumberInfo'),
  {
    loading: () => null,
  }
);
export const Success = dynamic(() => import('components/Shared/Icons/Success'), {
  loading: () => null,
});
export const Close = dynamic(() => import('components/Shared/Icons/Close'), {
  loading: () => null,
});
export const WarningIcon = dynamic(() => import('components/Shared/Icons/WarningIcon'), {
  loading: () => null,
});
export const AlertIconQbo = dynamic(() => import('components/Shared/Icons/AlertIconQbo'), {
  loading: () => null,
});
export const Edit = dynamic(() => import('components/Shared/Icons/Edit'), { loading: () => null });
export const SignOut = dynamic(() => import('components/Shared/Icons/SignOut'), {
  loading: () => null,
});
export const Gear = dynamic(() => import('components/Shared/Icons/Gear'), { loading: () => null });
export const CorpAgent = dynamic(() => import('components/Shared/Icons/CorpAgent'), {
  loading: () => null,
});
export const FatQuestionMark = dynamic(() => import('components/Shared/Icons/FatQuestionMark'), {
  loading: () => null,
});
export const NewAlertIcon = dynamic(() => import('components/Shared/Icons/NewLabel'), {
  loading: () => null,
});
export const SchedulePay = dynamic(() => import('components/Shared/Icons/Alert'), {
  loading: () => null,
});
export const Calendar = dynamic(() => import('components/Shared/Icons/Calendar'), {
  loading: () => null,
});
export const Clock = dynamic(() => import('components/Shared/Icons/Clock'), {
  loading: () => null,
});
export const ScheduledLogin = dynamic(() => import('components/Shared/Icons/ScheduledLogin'), {
  loading: () => null,
});
export const Apple = dynamic(() => import('components/Shared/Icons/Apple'), {
  loading: () => null,
});
export const Accepted = dynamic(() => import('components/Shared/Icons/Accepted'), {
  loading: () => null,
});
export const Declined = dynamic(() => import('components/Shared/Icons/Declined'), {
  loading: () => null,
});
export const TitleConfetti = dynamic(
  () => import('components/Core/SuccessScreen/TitleConfetti/TitleConfetti'),
  {
    loading: () => null,
  }
);
export const Confetti = dynamic(() => import('components/Shared/Icons/Confetti'), {
  loading: () => null,
});
export const Messaging = dynamic(() => import('components/Shared/Icons/Messaging'), {
  loading: () => null,
});
export const DigitalWallet = dynamic(() => import('components/Shared/Icons/DigitalWallet'), {
  loading: () => null,
});

type bugProps = {
  cdn: string;
  altText?: string;
};
export const PaymentMethodBugs = {
  GenericCard: ({ cdn, altText }: bugProps) => (
    <img height={22} width={36} src={`${cdn}/genericCard.bug.svg`} alt={altText} />
  ),

  Visa: ({ cdn, altText }: bugProps) => <img src={`${cdn}/visa.bug.svg`} alt={altText} />,

  MasterCard: ({ cdn, altText }: bugProps) => (
    <img src={`${cdn}/mastercard.bug.svg`} alt={altText} />
  ),

  Discover: ({ cdn, altText }: bugProps) => <img src={`${cdn}/discover.bug.svg`} alt={altText} />,

  Amex: ({ cdn, altText }: bugProps) => (
    <img src={`${cdn}/american-express.bug.svg`} alt={altText} />
  ),

  JCB: ({ cdn, altText }: bugProps) => <img src={`${cdn}/jcb.bug.svg`} alt={altText} />,

  Bank: ({ cdn, altText }: bugProps) => <img src={`${cdn}/bank.bug.svg`} alt={altText} />,

  PayPal: ({ cdn, altText }: bugProps) => <img src={`${cdn}/paypal.bug.svg`} alt={altText} />,

  ApplePay: ({ cdn, altText }: bugProps) => <img src={`${cdn}/applepay.bug.svg`} alt={altText} />,
};

export const getPaymentMethodBug = (
  paymentMethodName: string,
  cdn: string,
  formatMessage: Function
) => {
  let altText;
  switch (paymentMethodName) {
    case 'visa':
    case CardType.VISA:
      altText = formatMessage({
        id: 'VISA_LOGO_ALT',
        defaultValue: 'Visa logo',
      });
      return PaymentMethodBugs.Visa({ cdn, altText });

    case 'mastercard':
    case CardType.MASTERCARD:
      altText = formatMessage({
        id: 'MASTERCARD_LOGO_ALT',
        defaultValue: 'Mastercard logo',
      });
      return PaymentMethodBugs.MasterCard({ cdn, altText });

    case 'discover':
    case CardType.DISCOVER:
      altText = formatMessage({
        id: 'DISCOVER_LOGO_ALT',
        defaultValue: 'Discover logo',
      });
      return PaymentMethodBugs.Discover({ cdn, altText });

    case 'amex':
    case 'american express':
    case CardType.AMERICAN_EXPRESS:
      altText = formatMessage({
        id: 'AMEX_LOGO_ALT',
        defaultValue: 'Amex logo',
      });
      return PaymentMethodBugs.Amex({ cdn, altText });

    case 'jcb':
    case CardType.JCB:
      altText = formatMessage({
        id: 'JCB_LOGO_ALT',
        defaultValue: 'JCB logo',
      });
      return PaymentMethodBugs.JCB({ cdn, altText });

    case 'bank':
      altText = formatMessage({
        id: 'BANK_LOGO_ALT',
        defaultValue: 'Bank logo',
      });
      return PaymentMethodBugs.Bank({ cdn, altText });

    case 'paypal':
      altText = formatMessage({
        id: 'PAYPAL_LOGO_ALT',
        defaultValue: 'Paypal logo',
      });
      return PaymentMethodBugs.PayPal({ cdn, altText });

    case 'applepay':
      altText = formatMessage({
        id: 'APPLE_LOGO_ALT',
        defaultValue: 'Apple logo',
      });
      return PaymentMethodBugs.ApplePay({ cdn, altText });
    default:
      altText = formatMessage({
        id: 'GENERIC_CARD_ALT',
        defaultValue: 'Card',
      });
      return PaymentMethodBugs.GenericCard({ cdn, altText });
  }
};

if (!process || !process.env || process.env.NODE_ENV !== 'test') {
  // force preloading of some of them
  import('components/Core/Payment/PaymentForm/Bank/Ach/AccountNumberInfo/AccountNumberInfo');
  import('components/Shared/Icons/RoutingNumberInfo');
}
